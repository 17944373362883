import { Language } from "../language";
import { getRoute } from "@environment/type";

export default [
  //首页
  {
    path: getRoute() + "home",
    name: "Home",
    component: () => import("@/views/main/home/Home.vue"),
    meta: {
      title: Language.meta.homePage,
      footerShow: true,
      headerHide: true
    }
  },
  //购物车
  {
    path: getRoute() + "cart",
    name: "Cart",
    component: _ => import("@/views/main/shopping/Cart.vue"),
    meta: {
      title: Language.cart.title,
      requiredAuth: true,
      footerShow: true,
      headerHide: true
    }
  },
  // 用户中心
  {
    path: getRoute() + "user",
    name: "User",
    component: () => import("@/views/user/Index.vue"),
    children: [
      {
        path: "",
        redirect: getRoute() + "user/userManage"
      },
      {
        path: getRoute() + "user/userManage",
        name: "UserManage",
        component: () => import("@/views/user/user-manage/UserManage.vue"),
        meta: {
          title: Language.meta.userManagement,
          requiredAuth: true,
          footerShow: true,
          headerHide: true
        }
      },
      {
        path: getRoute() + "user/setUser",
        name: "setUser",
        component: () => import("@/views/user/set-user/SetUser.vue"),
        meta: {
          title: Language.meta.userSet,
          requiredAuth: true // 需要登录的页面,添加这个参数
        }
      },
      {
        path: getRoute() + "user/editNickname",
        name: "EditNickname",
        component: () => import("@/views/user/edit-nickname/EditNickname.vue"),
        meta: {
          title: Language.auth.editName,
          requiredAuth: true
        }
      },
      {
        path: getRoute() + "user/shippingAddress",
        name: "ShippingAddress",
        component: () =>
          import("@/views/user/shipping-address/ShippingAddress.vue"),
        meta: {
          title: Language.meta && Language.meta.shippingAddress,
          requiredAuth: true // 需要登录的页面,添加这个参数
        }
      },
      {
        //
        path: getRoute() + "user/editAddress",
        name: "EditAddress",
        component: () => import("@/views/user/edit-address/EditAddress.vue"),
        meta: {
          title: Language.meta && Language.meta.addAddress,
          requiredAuth: true // 需要登录的页面,添加这个参数
        }
      },
      {
        path: getRoute() + "user/editAddress/:id",
        name: "EditAddress",
        component: () => import("@/views/user/edit-address/EditAddress.vue"),
        props: true,
        meta: {
          title: Language.meta && Language.meta.editAddress,
          requiredAuth: true // 需要登录的页面,添加这个参数
        }
      },
      {
        // 优惠券
        path: getRoute() + "user/coupon",
        name: "Coupon",
        component: () => import("@/views/user/coupon/coupon.vue"),
        meta: {
          title: Language.userManage.coupon,
          requiredAuth: true // 需要登录的页面,添加这个参数
        }
      },
      {
        // track
        path: getRoute() + "user/track",
        name: "Track",
        component: () => import("@/views/user/track/Track.vue"),
        meta: {
          title: Language.meta.browsingHistory,
          requiredAuth: true // 需要登录的页面,添加这个参数
        }
      },
      {
        // attention
        path: getRoute() + "user/goodsAttention",
        name: "GoodsAttention",
        component: () =>
          import("@/views/user/goods-attention/GoodsAttention.vue"),
        meta: {
          title: Language.meta.attentionGoods,
          requiredAuth: true // 需要登录的页面,添加这个参数
        }
      },
      {
        // ShopAttention
        path: getRoute() + "user/shopAttention",
        name: "ShopAttention",
        component: () =>
          import("@/views/user/shop-attention/ShopAttention.vue"),
        meta: {
          title: Language.meta.attentionShop,
          requiredAuth: true // 需要登录的页面,添加这个参数
        }
      },
      {
        //support
        path: getRoute() + "user/support",
        name: "Support",
        component: () => import("@/views/user/support/Support.vue"),
        meta: {
          title: Language.support.helpCenter
        }
      },
      {
        path: getRoute() + "user/supportList",
        name: "SupportList",
        component: () => import("@/views/user/support/SupportList.vue"),
        meta: {
          title: Language.support.helpCenter
        }
      },
      {
        path: getRoute() + "user/supportDetail/:id",
        name: "SupportDetail",
        props: true,
        component: () => import("@/views/user/support/SupportDetail.vue"),
        meta: {
          title: Language.support.helpCenter
        }
      }
    ]
  },
  {
    path: getRoute() + "auth",
    component: _ => import("@/views/main/auth/Index.vue"),
    children: [
      {
        path: "",
        redirect: getRoute() + "auth/login"
      },
      {
        path: getRoute() + "auth/login",
        name: "Login",
        component: _ => import("@/views/main/auth/Login.vue"),
        meta: {
          title: Language.auth.login,
          headerHide: true
        }
      },
      {
        path: getRoute() + "auth/register",
        name: "Register",
        component: _ => import("@/views/main/auth/Register.vue"),
        meta: {
          title: Language.auth.register,
          headerHide: true
        }
      },
      {
        path: getRoute() + "auth/regInfo",
        name: "RegisterInfo",
        component: _ => import("@/views/main/auth/RegisterInfo.vue"),
        meta: {
          title: Language.auth.setAccountInfo,
          headerHide: true
        }
      },
      {
        path: getRoute() + "auth/forget1",
        name: "Forget1",
        component: _ => import("@/views/main/auth/ResetPasswordStep1.vue"),
        meta: {
          title: Language.auth.forgetPassword,
          headerHide: true
        }
      },
      {
        path: getRoute() + "auth/forget2",
        name: "Forget2",
        component: _ => import("@/views/main/auth/ResetPasswordStep2.vue"),
        meta: {
          title: Language.auth.validEmail,
          headerHide: true
        }
      },
      {
        path: getRoute() + "auth/forget3",
        name: "Forget3",
        component: _ => import("@/views/main/auth/ResetPasswordStep3.vue"),
        meta: {
          title: Language.auth.setNewPassword,
          headerHide: true
        }
      },
      {
        path: getRoute() + "auth/forget4",
        name: "Forget4",
        component: _ => import("@/views/main/auth/ResetPasswordStep4.vue"),
        meta: {
          title: Language.auth.finishPasswrod,
          headerHide: true
        }
      }
    ]
  },
  {
    path: getRoute() + "authentication",
    name: "Authentication",
    component: () => import("@/views/main/authentication/authentication.vue"),
    meta: {
      title: Language.authenticationInfo.checkAuth,
      requiredAuth: true
    }
  },
  {
    path: getRoute() + "messages",
    name: "Messages",
    component: () => import("@/views/user/messages/messages.vue"),
    meta: {
      title: Language.messages.messageCenter,
      requiredAuth: true
    }
  },
  {
    path: getRoute() + "messageInfo/:id",
    name: "MessageInfo",
    component: () => import("@/views/user/messageInfo/messageInfo.vue"),
    props: true,
    meta: {
      title: Language.messages.messageCenter,
      requiredAuth: true
    }
  },
  {
    path: getRoute() + "store",
    name: "Store",
    component: () => import("@/views/main/store/Store.vue"),
    meta: {
      title: Language.meta.store
    }
  },
  {
    path: getRoute() + "details",
    name: "Details",
    component: () => import("@/views/main/details/Details.vue"),
    meta: {
      title: Language.meta.productDetails
    }
  },
  {
    path: getRoute() + "orders",
    name: "Orders",
    component: _ => import("@/views/main/order/Orders.vue"),
    meta: {
      title: Language.meta.order,
      requiredAuth: true,
      backUser: false
    }
  },
  {
    path: getRoute() + "order",
    name: "Order",
    component: _ => import("@/views/main/order/Order.vue"),
    meta: {
      title: Language.meta.order,
      requiredAuth: true
    }
  },
  {
    path: getRoute() + "payment",
    name: "Payment",
    component: _ => import("@/views/main/payment/Payment.vue"),
    meta: {
      title: Language.pay.offlinePay,
      requiredAuth: true
    }
  },
  {
    path: getRoute() + "orderDetails/:id",
    name: "OrderDetails",
    component: _ => import("@/views/main/order-details/OrderDetails.vue"),
    props: true,
    meta: {
      title: Language.meta.orderdetail,
      requiredAuth: true
    }
  },
  {
    path: getRoute() + "payCompletion",
    name: "PayCompletion",
    component: _ => import("@/views/main/order/PayCompletion.vue"),
    meta: {
      title: Language.pay.title,
      backUser: true
    }
  },
  // OrderLogistics
  {
    path: getRoute() + "orderLogistics/:id",
    name: "OrderLogistics",
    component: () => import("@/views/main/order-logistics/OrderLogistics.vue"),
    props: true,
    meta: {
      title: Language.orderDetails.logisticsStatus,
      requiredAuth: true
    }
  },
  {
    // 搜索
    path: getRoute() + "search",
    name: "search",
    component: () => import("@/views/main/search/Search.vue"),
    props: true,
    meta: {
      title: Language.meta && Language.meta.search
    }
  },
  {
    path: getRoute() + "searchHistory",
    name: "searchHistory",
    component: () => import("@/views/main/search-history/SearchHistory.vue"),
    meta: {
      title: Language.meta.search,
      headerHide: true
    }
  },
  // 全部类目
  {
    path: getRoute() + "allProducts",
    name: "AllProducts",
    component: () => import("@/views/main/all-products/AllProducts.vue"),
    props: true,
    meta: {
      title: Language.meta && Language.meta.allProducts
    }
  },
  //关于我们
  {
    path: getRoute() + "aboutUs",
    name: "AboutUs",
    component: () => import("@/views/main/about/AboutUs.vue"),
    props: true,
    meta: {
      title: Language.settings && Language.settings.aboutUs
    }
  },
  //注销账户
  {
    path: getRoute() + "accountDeletion",
    name: "AccountDeletion",
    component: () => import("@/views/user/accountDeletion/accountDeletion.vue"),
    props: true,
    meta: {
      title: Language.settings && Language.settings.accountDelete
    }
  },
  {
    path: getRoute() + "terms",
    name: "Terms",
    component: () => import("@/views/main/terms/Index.vue"),
    children: [
      {
        path: "",
        redirect: getRoute() + "terms/agreement"
      },
      {
        path: getRoute() + "terms/agreement",
        name: "Agreement",
        component: () => import("@/views/main/terms/Agreement.vue"),
        meta: {
          title: Language.meta && Language.meta.Agreement,
          headerHide: true
        }
      },
      {
        path: getRoute() + "terms/privacy",
        name: "Privacy",
        component: () => import("@/views/main/terms/Privacy.vue"),
        meta: {
          title: Language.meta && Language.meta.privacy,
          headerHide: true
        }
      }
    ]
  },
  {
    path: getRoute() + "goodsTerms",
    name: "GoodsTerms",
    component: () => import("@/views/main/goodsTerms/Index.vue"),
    children: [
      {
        path: "",
        redirect: getRoute() + "goodsTerms/shippingRules"
      },
      {
        path: getRoute() + "goodsTerms/shippingRules",
        name: "ShippingRules",
        component: () => import("@/views/main/goodsTerms/ShippingRules.vue"),
        meta: {
          title: Language.meta && Language.meta.shippingRules,
          headerHide: true
        }
      },
      {
        path: getRoute() + "goodsTerms/returnRules",
        name: "ReturnRules",
        component: () => import("@/views/main/goodsTerms/ReturnRules.vue"),
        meta: {
          title: Language.meta && Language.meta.returnRules,
          headerHide: true
        }
      }
    ]
  }
];
