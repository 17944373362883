var messages = {
  privacy: {
    title: "Vancheen Service Usage Tips",
    text1: "Thank you for your trusty, welcome using Vancheen!",
    text2:
      "In order to better protect your rights and interests, please carefully read and fully understand",
    text3: "Individual User Service Agreement and Privacy Policy.",
    text4:
      "If you click 'agree', means that you have fully read, understood and accepted all the contents of this agreement, and this agreement will be legally binding. if you click “not agree”, you can exit from this page."
  },
  meta: {
    homePage: "Home",
    userPage: "Me",
    productDetails: "Product details",
    store: "Store",
    userManagement: "User management",
    attentionGoods: "Likes&Collects",
    attentionShop: "Follow Shops",
    browsingHistory: "Browsing Histories",
    userSet: "Settings",
    declaration: "Declaration",
    orderdetail: "Orderdetail",
    search: "Search for a product",
    special: "HotZone",
    editAddress: "Edit Address",
    shippingAddress: "Delivery Address Settings",
    addAddress: "Add Address",
    allProducts: "All Products",
    order: "My PO",
    newsCenter: "News Center",
    newArrival: "New Arrival",
    morePopular: "Hot Seller",
    authentication: "Authentication",
    videoCollection: "Video Collection",
    more: "More",
    Agreement: "User Service Agreement",
    privacy: "Privacy Policy",
    agree: "Agree",
    disagree: "Disagree",
    submit: "Submit",
    confirm: "Confirm",
    shippingRules: "Shipping and Delivery",
    returnRules: "Return Conditions And Rules",
    downloadText: "Download the Vancheen App to discover more",
    openShop: "Open my shop",
    viewShop: "See more",
    videoError:
      "This video is not available at this time, please try again later",
    nextworkError: "Network error",
    filter: "Filter",
    allFilter: "All conditions",
    isCouponProduct: "Discount goods",
    isBrandProduct: "Brand goods",
    isAttestationProduct: "Certified goods",
    attType: "Certification type",
    cSelect: "Please select"
  },
  auth: {
    login: "Log in",
    logInSuccess: "Log In success",
    register: "Sign up",
    setAccountInfo: "Set account information",
    forgetPassword: "Forget password",
    validEmail: "Validate e-mail address",
    setNewPassword: "Set a new password",
    finishPasswrod: "Finish",
    forgottenPassword: "Forgot Your Password?",
    sendAuthCode: "E-mail code",
    next: "Next",
    noAccountGoRegister: "No have an account? Register now",
    existingAccountToLogin: "Already Have An Account, Log On !",
    placeholderEmail: "Input E-mail",
    placeholderPassword: "Input Password",
    placeholderNewPassword: "Please enter your new password",
    placeholderAuthPassword: "Please enter again",
    placeholderEmailCode: "Input the Code",
    placeholderNickname: "Enter Nickname",
    errorEmail: "Error Email address, try again",
    errorEmailNotExist: "Account is not exist",
    errorPasswordNotMatch:
      "The new password does not match the confirmation password",
    passwordValid: "Password be 6-20 characters with digit mix letter.",
    nicknameValid: "Nickname be 5-25 characters with digit mix letter.",
    agreedVancheenRegulations: "Vancheen Regulations have been agreed",
    backLogin: "Back to login",
    resetPasswordStep1: "1.Verify registered email", //
    resetPasswordStep2: "2.Verify code", //
    resetPasswordStep3: "3.Set new password", //
    resetPasswordStep4: "4.Finish", //
    pleaseEnterEmail: "Please enter",
    verificationCodeInfo: "Verification code has been sent",
    verificationCodeErroe: "Verification code or email error",
    inputEmail: "Enter E-mail Address",
    username: "Username",
    email: "Email",
    pasTitle: "PIN Code",
    inputPaw: "Enter Log On PIN",
    pawInfo: "6-20 Characters With Digit Mix Letter.",
    registerSuccess: "Register successfully",
    correctError: "Please enter the correct password first",
    registerInfo: "Register successfully and download the app",
    editName: "Edit Nickname",
    inputNewName: "New Name",
    inputYourName: "Input New Name",
    tick: "Please tick ",
    rulesAndProtocols:
      "I agreed to Individual User Service Agreement and Privacy Policy",
    inputInviteCode: "Please input invitation code (optional)",
    inviteCode: "Invitation code"
  },
  messages: {
    messageCenter: "Message Center",
    isTop: "Top",
    unread: "Unread"
  },
  order: {
    title: "Order",
    selectAddress: "Select Delivery Address",
    shippingMethod: "Delivery Method:",
    freightAmount: "Delivery Fee",
    firstWeight(val) {
      return "1st " + val + "KG：";
    },
    secondWeight(val) {
      return "per " + val + "KG：";
    },
    total(val) {
      return "Total  " + val + " Item";
    },
    priceDetail: "Amount detail",
    shippingIncluded: "Including",
    coupon: "Coupon",
    totalFreight: "Freight amount",
    discountAmount: "Discount amount",
    productTotal: "Product total",
    allTotal: "Total",
    submitOrder: "Done",
    placeholderSelectAddressFirst: "Please select the address first",
    placeholderSelectAddress: "Select a shipping address",
    placeholderSelectFreight: "Please choose the delivery method",
    placeholderSelectEmpty:
      "There is no shipping method in this country, please change the address",
    stateAll: "All PO",
    stateUnpay: "Pending",
    stateWaitSending: "Delivered",
    stateReceiving: "Received",
    stateFinish: "Completed",
    noMore: "No more.",
    logistics: "Tracking",
    delete: "Delete",
    confirm: "Confirm Order",
    ordersTtitleInfo: "Item Total Amount (Including Freight:$"
  },
  alert: {
    cancelButtonText: "Cancel",
    confirmButtonText: "Confirm",
    orderConfirmText: "Are you sure to Confirm Order?",
    orderCancelText: "Are you sure to Cancel Order?",
    cancellationText: "Are you sure to Deactivate Account?",
  },
  pay: {
    title: "Pay",
    offlinePay: "Offline pay",
    cardPay: "Credit card online payment",
    lianlianPay: "Lian Lian payment",
    backHome: "Home",
    detail: "PO Details",
    success: "Payment Success",
    failure: "Payment Fail",
    payType: "Select payment method",
    onPay: "Pay now",
    payDetail: "Payment Detail",
    rePayDetail: "Pay again",
    cancelOrder: "Cancel Order",
    emptyPay: "No payment method available",
    selectPay: "Please select a payment method"
  },
  paymentInfo: {
    optional: "Optional",
    required: "Required",
    haveAuth: "Verified successfully",
    unAuth: "Unverified",
    onAuth: "Verifying",
    reAuth: "Verification failed",
    passPort: "driving number / passPort number",
    inputPassPort: "input driving number / passPort number",
    auth: "Authentication",
    nameAuth: "Authentication",
    checkAuth: "Verify",
    goAuth: "Go Verify",
    title: "Please upload a screenshot of the payment voucher",
    yourName: "Your Name",
    yourNamePlc: "Your Name",
    bankName: "Bank Name",
    bankNamePlc: "Please Entry Bank Name Of Your Account.",
    bankAccount: "Bank Account",
    bankAccountPlc: "No.",
    iBankNo: "IBank No.",
    iBankNoPlc: "Please Entry Your IBank No.",
    swiftCode: "Swift Code",
    swiftCodePlc: "Swift Code",
    bankAddress: "Bank Address",
    bankAddressPlc: "Please Entry The Bank Address Of Your Account.",
    yourPostAdd: "Your Post Add",
    yourPostAddPlc: "Your Post Add",
    done: "Done",
    resubmit: "Resubmit",
    reason: "Reason",
    authMessage:
      "You have not verified your identity information, please go to verify your identity and complete the information",
    accountName: "Account Name",
    accountNumber: "Account Number",
    upload: "Upload",
    verifying: "Verifying",
    verifyFailed: "Not passed",
    verifySuccess: "Passed",
    verifyInfo:
      "Please transfer to the bank account below, fill in the payment account information and provide a screenshot of the bank transfer voucher"
  },
  cart: {
    title: "Cart",
    total(val) {
      return "A total of " + val + " items";
    },
    manage: "Manage",
    finish: "Done",
    checkAll: "All",
    excludeFreight: "without shipping",
    payMoney: "Total:",
    delete: "Delete",
    clearing: "Pay",
    recommend: "Recommend",
    selectItems: "Please select a product",
    empty: "Shopping cart is empty",
    gogogo: "Go for a stroll",
    productOff: "This product is unavailable",
    productsOff: "These product are unavailable",
    inventory: "Inventory",
    inventoryState1: "Under stock",
    inventoryState2: "Out of stock",
  },
  errorParamGoBack: "Parameter error, please return to previous page",
  unselected: "unselected",
  okText: "OK",
  resetText: "Reset",
  address: {
    pleaseEnter: "Please Input ",
    name: "Name",
    address: "Detail Address",
    phone: "Contact number",
    postcode: "Post Code",
    addAddress: "+ Delivery Address",
    country: "Location",
    city: "City",
    consigneeName: "Consignee",
    setDefaultAddress: "Set As Default Address",
    select: "Please Select"
  },
  editAddress: {
    done: "Done",
    delete: "Delete"
  },
  second: "S",
  name: "Name",
  tel: "Phone",
  save: "Save",
  confirm: "Confirm",
  cancel: "Cancel",
  delete: "Delete",
  complete: "Complete",
  loading: "Loading...",
  telEmpty: "Please fill in the tel",
  nameEmpty: "Please fill in the name",
  confirmDelete: "Are you sure you want to delete?",
  telInvalid: "Malformed phone number",
  forwardToEarn: "Earn",
  // homelist国际化
  homeList: {
    Sold: "Sold"
  },
  homeIcon: {
    newProduct: "New",
    ladies: "Ladies",
    digital: "Digital",
    hotSale: "Hot sale"
  },
  up: "Up",
  sell: "sell",
  piece: "piece",
  noMoreData: "More wonderful are coming soon!",
  noDetails: "No details",
  supplier: "Supplier",
  in: "In",
  addToStore: "Add to store",
  forwardGoods: "Forward goods",
  belongToSupplier: "You are a regular distributor of suppliers",
  notAreToSupplier: "You are not an ordinary distributor of suppliers",
  attention: "Attention",
  operateSuccessfully: "Operate successfully",
  forward: "Forward",
  todayTurnover: "Today's turnover",
  todayOrder: "Today's order",
  visitorsToday: "Visitors today",
  earnings: "Earnings",
  commodity: "Commodity",
  indent: "Indent",
  operatingData: "Operating data",
  tutorials: "Tutorials",
  soldOut: "Sold out",
  sold: "Sold",
  putaway: "Putaway",
  put: "Put",
  all: "All",
  sellingPrice: "Selling price",
  sales: "Sales",
  earn: "Earn",
  interpret: "Interpret",
  unstick: "Unstick",
  stick: "Stick",
  updatePrice: "Set Price",
  mainHeading: "Main heading",
  subheading: "Subheading",
  specification: "Specification",
  cost: "Cost",
  profit: "Profit",
  msetKeyValue: "Mset key value",
  discount: "Discount",
  brand: "Brand",
  attestation: "Attestation",
  // 用户管理
  userManage: {
    allOrders: "More Orders",
    notDeliverGoods: "Not deliver goods",
    confiscatedGoods: "Received",
    haveFinished: "Have finished",
    allGoods: "All goods",
    attentionGoods: "Likes&Collects",
    attentionShop: "Follow Shops",
    browsingHistory: "Review",
    coupon: "Coupon",
    waitGoods: "Delivered",
    waitPayment: "Pending",
    haveGoods: "Completed",
    myOrder: "My PO"
  },
  couponInterpret: {
    state1: "Unuse",
    state2: "Have been used",
    state3: "Unavailable",
    state4: "Unavailable",
    goUse: "Use",
    endDate: "Maturity time",
    noCondition: "No threshold",
    condition(value) {
      return "Use up to " + value;
    },
    totalCount(value) {
      return value + " coupons";
    },
    couponCount(value) {
      return "¥ " + value + " coupon";
    },
    alertTitle: "You have received these coupon",
    selectionTitle: "Please select coupon",
    selectedCoupon: "Selected coupon",
    discountTotal: "Total discount",
    canuseTitle: "Available coupons",
    notuseTitle: "Not available coupons",
    notuseCoupon: "Not use coupon",
    reason: "The reason of this order is unavailable: It does not meet the usage conditions"
  },
  support: {
    title: "Technical Support",
    dateHour: "Business hours : 9:00-18:00 (GMT +8)",
    dateWeek: "Business day : Monday – Friday",
    helpCenter: "Contact us",
    email: "Email",
    select: "Please select a service",
    supportOption1: "Complaints and Suggestions",
    supportOption2: "Others",
    enterType: "Please choose the type",
    enterSupport: "Please input the content",
    enterEmail: "Please input the email address",
    contactWay: "Your contact information",
    supportList: "View submitted content",
    serviceType: "Service type",
    serviceTitle: "Question title",
    serviceStatus: "Problem status",
    serviceTime: "Submission time",
    serviceDes: "Problem Description",
    serviceContact: "Contact information",
    servicePosted: "Posted by",
    serviceContent: "Reply content",
    serviceReplyTime: "Response Time",
    serviceReplyContent: "Reply content",
    serviceInputReplyContent: "Please Input the reply content",
    copyRight: "Copy right",
    currentVersion: "Current version",
    updateVersion: "Version update"
  },
  goodsAttention: {
    sellingPrice: "Price"
  },
  orderDetails: {
    logisticsStatus: "Tracking Detail",
    consignee: "Consignee",
    shippingAddress: "Delivery Address",
    productTotalPrice: "Item Amount",
    freight: "Delivery Fee",
    actualPayment: "Actual payment",
    total: "Total",
    contactTheShop: "Contact Supplier",
    orderNumber: "Order number",
    logisticsNumber: "Logistics tracking number",
    orderTime: "Order time",
    timeOfPayment: "Time of payment",
    payment: "Paid",
    product: "Product",
    totalPrice: "total price",
    supplier: "Supplier",
    unpayText(time) {
      return (
        "Please pay within " +
        time +
        ", overdue orders will be automatically cancelled"
      );
    },
    ordersDelivery(delivery) {
      return "(Including total freight: $" + delivery + ")";
    },
    totalAmount: "Item Total Amount:"
  },
  settings: {
    userTerms: "User terms",
    userRules: "Platform rules",
    aboutUs: "About us",
    profilePhoto: "Background Photo",
    nickname: "My Nick Name",
    myShippingAddress: "Delivery Address",
    accountDelete: "Account Cancellation",
    accountDeleteSuccess: "Account deactivated successfully",
    logOut: "Log out",
    label1: "Information modification issues",
    label2: "Shopping experience issues",
    label3: "Other issues",
    issue1: "Unable to change account name",
    issue2: "Mobile credit issues",
    issue3: "Change of email",
    issue4: "Duplicate account",
    issue5: "Unsatisfied with transaction dispute resolution",
    issue6: "Payment issues encountered",
    issue7: "Security and privacy concerns",
    issue8: "Other issues",
    inputText: "Please enter other issues",
    nextStep: "Next step",
    error: "Please select a issues",
    termsText: "I have read and agree to the 《Important Reminders for Account Cancellation》"
  },
  deliveryAddress: {},
  searchInfo: {
    general: "General",
    sales: "Sales",
    forwards: "Forwards"
  },
  // 详情与规格
  detailsInterpret: {
    productInfo: "Product Details",
    weight: "Weight",
    bulk: "Volum",
    noInformationAtPresent: "No Description Data",
    buy: "Buy Now",
    addcart: "Add to Cart",
    quantity: "Quantity",
    pleaseSelect: "Select Origin",
    submit: "Submit",
    detailsSold: "Sold",
    inventory: "Inventory",
    inventoryText: "This product is out of stock",
    inventoryLimit: "Quantity is over the inventory",
    productDescription: "Product Description",
    none: "None",
    upSold: number => {
      return "The Quantity Cannot Be Less Than " + number;
    }
  },
  // c注册b
  authenticationInfo: {
    haveAuth: "Verified successfully",
    unAuth: "Unverified",
    onAuth: "Verifying",
    reAuth: "Verification failed",
    passPort: "Driving License No. / Passport No.",
    inputPassPort: "Input Driving License No. / Passport No.",
    auth: "Authentication",
    nameAuth: "Authentication",
    checkAuth: "Register shop",
    goAuth: "Go Verify",
    title:
      "Please upload clean image of your passport or driving license（front and back side）",
    authState: "Verify state",
    yourName: "Real Name",
    yourNamePlc: "Your Name",
    bankName: "Bank Name",
    bankNamePlc: "Please Entry Bank Name Of Your Account.",
    bankAccount: "Bank Account",
    bankAccountPlc: "No.",
    iBankNo: "IBank No.",
    iBankNoPlc: "Please Entry Your IBank No.",
    swiftCode: "Swift Code",
    swiftCodePlc: "Swift Code",
    bankAddress: "Bank Address",
    bankAddressPlc: "Please Entry The Bank Address Of Your Account.",
    yourPostAdd: "Mailing Address",
    yourPostAddPlc: "Mailing Address",
    done: "Done",
    reason: "Reason",
    authMessage:
      "You have not completed the real name authentication, please go to complete the verification",
    submitMessage:
      "Your identity verification is under review, please wait patiently",
    failMessage:
      "Your identity verification failed, please re-verify your identity",
    shopName: "Shop name",
    yourShopName: "Please Enter Your Shop Name"
  },
  todayData: "Today's data",
  turnover: "Turnover",
  netIncome: "Net income",
  orderQuantity: "Order quantity",
  visitor: "Visitor",
  historicalData: "Historical data",
  historySearch: "Search history",
  cumulativeTurnover: "Cumulative turnover",
  cumulativeNetIncome: "Cumulative net income",
  cumulativeOrder: "Cumulative order",
  cumulativeVisitors: "Cumulative visitors",
  pleaseEnterContent: "Please enter content",
  waitPayment: "wait payment",
  unfollow: "Un-follow",
  cancelCollection: "Un-collection",
  copy: "Copy",
  copySuccess: "Copy success",
  nonsupportCopy: "Nonsupport copy",
  specialOffer: "Special Offer",
  cStore: {
    hotSale: "Hot Sale",
    allProducts: "All Products",
    sold: "Sold",
    pleaseEnterContent: "Please enter content"
  },
  plugins: {
    unavailable: "This feature requires access to the client to use",
    download: "Go download",
    openApp: "Open Vancheen App",
    openAppText: "Click on the top right corner to open in the default browser"
  }
};
export default messages;
