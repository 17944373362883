import { isAPP, getPlatform } from "@environment/type";
import { commonAction } from "@environment/index";

export const setAlias = (data) => {
    if (isAPP() && getPlatform() == 'ios') {
        window.JPush.setAlias({ sequence: 100, alias: data.alias });
    }
}

export const initJpush = () => {
    if (isAPP() && getPlatform() == 'ios') {

        function onDeviceReady() {
            initiateUI();
        };

        function onResume() {
            window.JPush.setApplicationIconBadgeNumber(0);
        }

        function getRegistrationID() {
            window.JPush.getRegistrationID(onGetRegistrationID);
        }

        function onGetRegistrationID(data) {

        };

        function onOpenNotification(event) {
            if (event.extras && event.extras.path) {
                window.JPush.setApplicationIconBadgeNumber(0);
                //console.log('jpush data: ' + JSON.stringify(event.extras));
                commonAction('routerPush', {
                    isParams: true,
                    path: event.extras.path,
                    name: event.extras.name ? event.extras.name : event.extras.path,
                    query: event.extras.query ? event.extras.query : [],
                })
            }
        };

        function initiateUI() {
            window.JPush.init();
            window.JPush.resumePush();
            window.JPush.setDebugMode(true);
            window.setTimeout(getRegistrationID, 1000);
            window.JPush.setApplicationIconBadgeNumber(0);
        };


        document.addEventListener("deviceready", onDeviceReady, false);//cordova加载完成后触发事件
        document.addEventListener("resume", onResume, false);//从后台返回 
        document.addEventListener("jpush.openNotification", onOpenNotification, false);//点开通知事件
    }
}


export default {
    setAlias,
    initJpush,
}