import { isAPP, getPlatform } from "@environment/type";
import { commonAction } from "@environment/index";

export const initDeepLinks = () => {
    if (isAPP() && getPlatform() == 'ios') {

        function onDeviceReady() {
            universalLinks.subscribe('openDetailPage', (eventData) => {
                //console.log('deeplinks data: ' + JSON.stringify(eventData));
                 //window.location.href = "app://localhost/app/details?ProductNumber=" + eventData.params.ProductNumber + "&ShopId=" + eventData.params.ShopId;
                commonAction('routerPush', {
                    isParams: true,
                    path: "details",
                    name: "details",
                    query: [
                        {
                            name: "ProductNumber",
                            value: eventData.params.ProductNumber,
                        },
                        {
                            name: "ShopId",
                            value: eventData.params.ShopId,
                        }
                    ],
                })
            });

            universalLinks.subscribe('openPayCompletionPage', (eventData) => {
                //console.log('deeplinks data: ' + JSON.stringify(eventData));
                 //window.location.href = "app://localhost/app/payCompletion?status=" + eventData.params.status + "&message=" + eventData.params.message;
                commonAction('routerPush', {
                    isParams: true,
                    path: "payCompletion",
                    name: "payCompletion",
                    query: [
                        {
                            name: "status",
                            value: eventData.params.status,
                        },
                        {
                            name: "message",
                            value: eventData.params.message,
                        }
                    ],
                })
            });
        };


        document.addEventListener("deviceready", onDeviceReady, false);//cordova加载完成后触发事件
    }
}


export default {
    initDeepLinks,
}